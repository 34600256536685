<template lang="pug">
.roles-shell
    .box
        .box__header
            TitlePage(
                title="Digital Trace"
                :breadcrumb="breadcrumb"
                )
        .box__body
            router-view
</template>

<script>
import TitlePage from '@/components/shell/TitlePage.vue'
export default {
    name: 'pageTargetTagsShell',
    components: {
        TitlePage,
    },
    data() {
        return {
            // 
        }
    },
    computed: {
        route() {
            return this.$route;
        },
        // id() {
        //     return this.$route.params.id;
        // },
        breadcrumb() {
            let breadcrumb = [
                {
                    name: 'Digital Trace',
                    url: '/target-tags',
                },
            ];
            if (this.route.matched[0] && this.route.matched[0].components.default.name === 'pageTargetTagsShell' && this.route.matched[1]) {
                switch(this.route.matched[1].name) {
                    case 'pageTargetTagsDetail':
                        breadcrumb.push({
                            name: 'Detail',
                            url: '/target-tags/detail',
                        });
                        break;
                    default:
                        // code block
                }
            }

            return breadcrumb;
        },
    },
    watch: {
        route() {},
        id() {},
        breadcrumb() {},
    },
    created() {
        // 
    },
    mounted() {
        // 
    },
}
</script>
